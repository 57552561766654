<template>
  <section class="coreHighlights">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <section class="title">
        <el-image :src="options.img1"></el-image>
        <p class="ch"><strong>助力飞产品介绍及核心亮点</strong></p>
        <div class="line"></div>
        <p class="en"><strong>Core Highlights</strong></p>
      </section>
      <el-row :gutter="50" class="main">
        <el-col :span="12">
          <div class="top"><div class="tit">何为助力飞现代智慧供应链？</div></div>
          <p>助力飞现代智慧供应链，是一款基于SaaS架构的一体化信息系统，通过独创的商业模式，旨在实现企业版"拼多多"和基于用户共享库存的分布式电子商城。助力飞的商业模式是为中小企业提供低成本的一体化信息系统，在大幅提升企业管理效率的同时，标准化中小企业的库存管理和订单履约流程。经标准化后的商品将作为共享库存自动在商城中上架，且优先提供给其他助力飞用户，实现共享库存的电子商城。同时，助力飞构建厂家/总代直供为基础的供应链赋能平台，其特点是产品供给方具有唯一性，形成拼单集量采购效应，降低需方采购成本，而且在需方仅有采购初步意向时，即提供厂家级别的服务，帮助需方更好的达成业务。不仅如此，助力飞5G版本包含了自建商城的板块，能够为需要建立采购商城的企业提供自建商城的产品和服务，助力飞将已标准化的共享库存打包嵌入自建采购商城用户，丰富商城的商品库，与需方实现双赢。另外，助力飞还积极探索与其他主流平台商进行对接，分享助力飞的共享库存，帮助助力飞用户获取新的销售机会和业务流量。总而言之，助力飞对于中小微企业用户来说，不仅能够提高自身信息化管理水平，还能够为用户带来新的销售订单。</p>
        </el-col>
        <el-col :span="12"><el-image :src="options.img5" class="img1"></el-image></el-col>
      </el-row>
      <el-row :gutter="30" class="main">
        <div class="top"><div class="tit">助力飞的核心亮点</div></div>
        <el-image :src="options.img2" class="img1"></el-image>
        <p>电商行业的供应产品的SKU数都是以“万”为基本单位；电商行业尤其以央企电商供应模式往往都是“以销定采”和追求零库存而且要求响应及时，对于企业的管理要求非常高。而“助力飞”可以很好的解决以下问题：</p>
        <p>（一）解决了传统ERP系统无法服务“以销定采”商业模式额痛点；</p>
        <p>（二）解决了企业面对数以万计产品的采购难点，为企业减少采购成本增加利润；</p>
        <p>（三）将企业的OA流程和进销存结合在一起，将决策机制前置，优化企业的管理；</p>
        <p>（四）帮助终端客户和头部服务商管控下级服务商。</p>
      </el-row>
    </section>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
export default {
  name: 'zhulifei',
  components: {
    imageBanner,
  },
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/05-助力飞.png'),
        slogan: require('../../assets/images/common/slogan.png'),
      },
      options: {
        img1: require('../../assets/images/2023/zhulifei/页面4-1.png'),
        img2: require('../../assets/images/2023/zhulifei/页面4-2.png'),
        img5: require('../../assets/images/2023/zhulifei/页面图-03.png'),
      }
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      // let id = (this.$route.query.id).toString()
      // switch (id) {
      //   case '1':
      //     this.options = this.$store.getters.getTeam1 && JSON.parse(JSON.stringify(this.$store.getters.getTeam1))
      //     break
      //   case '2':
      //     this.options = this.$store.getters.getTeam2 && JSON.parse(JSON.stringify(this.$store.getters.getTeam2))
      //     break
      //   case '3':
      //     this.options = this.$store.getters.getTeam3 && JSON.parse(JSON.stringify(this.$store.getters.getTeam3))
      //     break
      //   case '4':
      //     this.options = this.$store.getters.getTeam4 && JSON.parse(JSON.stringify(this.$store.getters.getTeam4))
      //     break
      //   default:
      //     this.options = this.$store.getters.getTeam1 && JSON.parse(JSON.stringify(this.$store.getters.getTeam1))
      //     break
      // }
    }
  }
}
</script>

<style scoped>
.coreHighlights {background-color: #ecf0f5; padding-bottom: 1rem;}
.container {padding: 1rem 10%;}
.coreHighlights .title {text-align: center; padding: 1rem 0 2rem;}
.coreHighlights .title .el-image {width: auto;}
.coreHighlights .ch, .coreHighlights .en {font-size: 3rem}
.coreHighlights .ch {color: #475f76}
.coreHighlights .en {color: #ccc;}
.coreHighlights .line {width: 40%; height: 2px; background-color: #ccc; margin: 0 auto;}
.container p {line-height: 2; text-indent: 2em;}
.container .details {padding: 0 2rem; font-size: 1.2rem; line-height: 2;}
.coreHighlights .main {padding: 0 0 2rem;}
.coreHighlights .main .top {border-top: 4px solid #999; line-height: 2; text-indent: 2em; padding-bottom: 3rem;}
.coreHighlights .main .tit {width: 60%; background-color: #ccc; border-radius: 0 0 2rem 0; font-weight: 600; font-size: 1.4rem;}
.coreHighlights .img1 {padding: 0; width: 100%;}
</style>